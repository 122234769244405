import React from 'react';
import Helmet from '../project/Helmet';
import { t } from '@lingui/macro';
import CustomerGroupAdd from './customerGroups/CustomerGroupAdd';
import CustomerGroupList from './customerGroups/CustomerGroupList';

/**
 * @dusan
 */

class CustomerGroupsPage extends React.PureComponent {
    render() {
        return <React.Fragment>
            <Helmet
                title={t`Zákaznícke skupiny`}
            />
            <div className="full-size-height d-flex flex-column">
                <div className="pt-2 add-max-width flex-item-static">
                    <CustomerGroupAdd/>
                </div>
                <div className="m-3 overflow-auto flex-item-dynamic-1">
                    <CustomerGroupList/>
                </div>
            </div>
        </React.Fragment>;
    }

}

export default CustomerGroupsPage;