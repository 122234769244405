import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../fetch/FormModal';
import generalFailedPC from '../../fetch/generalFailedPC';
import withDataHOC from '../../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import { RIGHTS } from '../../../constants/Rights';
import CustomerGroupForm from './CustomerGroupForm';

/**
 * @dusan
 */

class CustomerGroupAdd extends React.PureComponent {
    static propTypes = {
        buttonType: PropTypes.string,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    }.isRequired;

    static defaultProps = {
        buttonType: "primary"
    };

    render() {
        const {buttonType, [GLOBAL_DATA.RELOAD_DATA]: reloadData} = this.props;
        return <RightsWrapper from={RIGHTS.SUPERVISOR}>
            <FormModal
                openNode={
                    <Button className="full-size-width" type={buttonType} icon="plus">
                        <span className="ml-2"><Trans>Pridanie zákazníckej skupiny</Trans></span>
                    </Button>
                }
                values={{isEdit: false}}
                onFinishSuccessful={() => {reloadData([GLOBAL_DATA.CUSTOMER_GROUPS])}}
                title={<Trans>Pridanie zákazníckej skupiny</Trans>}
                Form={CustomerGroupForm}
                Response={null}
                Failed={generalFailedPC(t`Nepodarilo sa vytvoriť novú zákaznícku skupinu`)}
            />
        </RightsWrapper>;
    }
}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(CustomerGroupAdd);