import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../fetch/FormModal';
import generalFailedPC from '../../fetch/generalFailedPC';
import withDataHOC from '../../dataProvider/withDataHOC';
import Tooltip from '../../general/Tooltip';
import {CustomerGroup} from '../../../constants/propTypesDefinitions';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import CustomerGroupForm from './CustomerGroupForm';

/**
 * @dusan
 */

class CustomerGroupEdit extends React.PureComponent {
    static propTypes = {
        group: CustomerGroup.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    }.isRequired;

    render() {
        const {group} = this.props;
        const reloadData = this.props[GLOBAL_DATA.RELOAD_DATA];
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Úprava zákazníckej skupiny</Trans>}>
                    <Button className="mx-1" icon="edit" size="small"/>
                </Tooltip>
            }
            values={{isEdit: true, group: group}}
            onFinishSuccessful={() => {reloadData([GLOBAL_DATA.CUSTOMER_GROUPS])}}
            title={<Trans>Úprava zákazníckej skupiny</Trans>}
            Form={CustomerGroupForm}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa upraviť zákaznícku skupinu`)}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(CustomerGroupEdit);